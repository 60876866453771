<template>
  <div class="checkWork"  v-loading.fullscreen.lock="fullscreenLoading">
    <div class="checkWork-con">
        <div class="vedio" @dragover="dragover($event)">
            <transition-group class="transition-wrapper" name="sort">
                <div v-for="item in dataList" :key="item.id" class="vedioItem" 
                        :draggable="true" 
                        @dragstart="dragstart(item)"
                        @dragenter="dragenter(item,$event)"
                        @dragend="dragend(item,$event)"
                        @dragover="dragover($event)"> 
                    <div class="vedioImg"  element-loading-background="rgba(0, 0, 0, 0.8)">
                        <img :src='item.appletExpandCourse.videoImg' alt="上传视频" class="img" @click="videoView(item)">
                        <el-row>
                            <el-col :span="20">
                                <div class="vedioName">
                                    <span>{{item.appletExpandCourse.name}}</span>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <i class="el-icon-delete deleteIcon" @click="sureDelete(item)"></i>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <!-- 此处的key="2020"为动画的标识 -->
                <div class="addImg" key="2020" @click="selectData">
                    <div class="add">
                        <div class="icon">
                            <i class="el-icon-circle-plus-outline"></i>
                        </div>
                        <div class="words">
                            从资源库选择
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
    <el-dialog title="提示" :visible.sync="deleteDialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
        <div class="checkSucCard">
            <div class="checkSuc">
                <div class="tip"></div>
                <div class="delete">
                    <div class="deleteImg">
                         <div class="deleteImgTip">{{deleteImgTip}}</div>
                    </div>
                </div>
                <div class="operation">
                    <div class="cancel" @click="cancel">取消</div>
                    <div class="deleteImg" @click="deleteImg">确认</div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="selectDialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
            <div class="modal">
                <div class="close" @click="close"></div>
                <div class="header">
                    <el-row>
                        <el-col :span="12">
                            <div class="modalName">
                                <span>添加拓展课</span>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="btnCtrol">
                                <el-input placeholder="搜索视频名称" v-model="courseName" style="width: 250px">
                                    <el-button slot="append" icon="el-icon-search" @click="searchVedioList"></el-button>
                                </el-input>
                                <div class="addBtn">
                                    <el-button type="success" round :disabled="isSel" @click="addNewVedio">添加为拓展课</el-button>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="content">
                    <div v-for="item in allVadioSelList" :key="item.id" class="courseItem"> 
                        <div class="course"  element-loading-background="rgba(0, 0, 0, 0.8)">
                            <img :src='item.videoImg' alt="上传视频"  class="img">
                            <el-row>
                                <el-col :span="20">
                                    <div class="vedioName">
                                        <span>{{item.name}}</span>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="check">
                                        <el-checkbox v-model="item.isCheck" :disabled="item.selected === 1" class="checkbox"></el-checkbox>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
            </div>
    </el-dialog>
    <el-dialog title="视频" :visible.sync="videoDialogVisible" width="50%" @close="videoClose" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <video-player class="video-player vjs-custom-skin" 
            ref="videoPlayer" 
            :playsinline="true" 
            v-if="videoShow"
            :options="playerOptions">
      </video-player>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="videoClose">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { page } from './../../api/index.js';
import { Message } from 'element-ui';
export default {
  name: "classroomFocus",
  
  data() {
    return {
        paikeId: '',
        infoId: '',
        boyAva: require('../../assets/image/guide.png'),
        dataList: [],
        fullscreenLoading: false,
        oldData: null,
        newData: null,
        deleteDialogVisible: false,
        deleteImgTip: '确定删除该视频？',
        selectDialogVisible: false,
        courseName: '',
        videoShow: false,
        id: '',
        allVadioSelList: [],
        videoDialogVisible: false,
        playerOptions: {
            playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
            autoplay: false, // 如果为true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 是否视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
            {
              type: "video/ogg", // 类型
              src: '' // url地址
            },
            {
              type: "video/mp4", // 类型
              src: '' // url地址
            },
            {
              type:  "video/webm" , // 类型
              src: '' // url地址
            },
            {
              type: "video/3gp", // 类型
              src: '' // url地址
            },
            ],
            poster: '', // 封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true, // 当前时间和持续时间的分隔符
              durationDisplay: true, // 显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间功能
              fullscreenToggle: true // 是否显示全屏按钮
            }
          }
    };
  },
 
  created() {
    this.getCoursePaikeId();
    // this.getPaikeIdForQuery().then((res) => {
    //     this.getBasicInfo(res)
    // }).catch(() => {
    //     this.getPaikeId().then((res) => {
    //         this.getBasicInfo(res)
    //     }).catch(() => {
    //         this.getPaikeIdForCurrDayLatestClass().then((res) => {
    //             this.getBasicInfo(res)
    //         }).catch(() => {});
    //     });
    // })
  },
  computed:{
      isSel(){
          let status = this.allVadioSelList.some(item => {
              return item.isCheck
          })
          return !status
      }
  },
  methods: {
        // getBasicInfo(res){
        //     this.paikeId = res.data.paikeId;
        //     this.infoId = res.data.infoId;
        //     this.aflpExpandCourseList();
        // },
        async getCoursePaikeId(){
            this.fullscreenLoading = true;
            await page.getCoursePaikeId('', res => {
                console.log(res);
                if(res.status === 1) {
                    if(res.data && res.data.courseName){
                        this.paikeId = res.data.scheduleId;
                        this.infoId = res.data.infoId;
                        this.aflpExpandCourseList();
                    }else{
                        Message.error('未查询到排课Id');
                    }
                }else {
                    Message.error(res.message);
                }
                this.fullscreenLoading = false;
            });
        },
        // getPaikeId(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayOnClass({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     that.fullscreenLoading = false;
        //                     resolve(res)
        //                 }else{
        //                     that.fullscreenLoading = false;
        //                     reject()
        //                 }   
        //             }else {
        //                 that.fullscreenLoading = false;
        //                 Message.error(res.message);
        //             }
        //         });
        //     })
        // },
        // getPaikeIdForCurrDayLatestClass(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayLatestClassTime({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     resolve(res)
        //                 }else{
        //                     reject()
        //                 }
        //             }else {
        //                 Message.error(res.message);
        //             }
        //             that.fullscreenLoading = false;
        //         });
        //     })
        // },
        // getPaikeIdForQuery(){
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         if(!that.$route.query.paikeId){
        //             reject()
        //         }else{
        //             page.getCourseInfoByPaikeId(that.$route.query.paikeId, res => {
        //                 if(res.status === 1) {
        //                     if(res.data && res.data.courseName){
        //                         resolve(res)
        //                     }else{
        //                         reject()
        //                     }
        //                 }else {
        //                     Message.error(res.message);
        //                 }
        //             });
        //         }
        //     })
        // },
        aflpExpandCourseList(){
            this.fullscreenLoading = true;
            page.aflpExpandCourseList(this.paikeId,res => {
                if(res.status === 1) {
                    this.dataList = res.data;
                    this.fullscreenLoading = false;
                }else {
                    this.fullscreenLoading = false;
                    Message.error(res.message);
                }
            });
        },
        dragstart(value) {
            this.oldData = value
        },
        // 记录移动过程中信息
        dragenter(value, e) {
            this.newData = value
            e.preventDefault()
        },
        // 拖拽最终操作
        dragend(value, e) {
            console.log(value, e)
            if (this.oldData !== this.newData) {
                let oldIndex = this.dataList.indexOf(this.oldData)
                let newIndex = this.dataList.indexOf(this.newData)
                let newItems = [...this.dataList]
                // 删除老的节点
                newItems.splice(oldIndex, 1)
                // 在列表中目标位置增加新的节点
                newItems.splice(newIndex, 0, this.oldData)
                this.dataList = [...newItems]
                const params = {
                    source: this.oldData.id,
                    target: this.newData.id
                }
                page.aflpExpandCourseSort(params, res => {
                    if(res.status === 1) {
                        Message.success('调换成功！');
                        this.aflpExpandCourseList();
                    }else {
                        Message.error(res.message);
                    }
                });
            }
        },
        // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
        dragover(e) {
            e.preventDefault()
        },
        sureDelete(item){
            this.id = item.id;
            this.deleteDialogVisible = true;
        },
        cancel(){
            this.deleteDialogVisible = false;
        },
        // 确认删除
        deleteImg(){
            page.aflpExpandCourseDelete(this.id, res => {
                if(res.status === 1) {
                    this.deleteDialogVisible = false;
                    Message.success('删除成功！');
                    this.aflpExpandCourseList();
                }else {
                    Message.error(res.message);
                }
            });
        },
        async selectData(){
            await this.appletExpandResourcesCourseList();
            this.selectDialogVisible = true;
        },
        async appletExpandResourcesCourseList(){
            await page.appletExpandResourcesCourseList(this.infoId,this.courseName,this.paikeId, res => {
                if(res.status === 1) {
                    this.allVadioSelList = res.data.map(item => ({
                        ...item,isCheck: false
                    }))
                }else {
                    Message.error(res.message);
                }
            });
        },
        close(){
            this.selectDialogVisible = false;
        },
        // 查询资源库
        searchVedioList(){
            this.appletExpandResourcesCourseList();
        },
        // 添加拓展课
        addNewVedio(){
            let selData = this.allVadioSelList.filter(item => {
                return item.isCheck
            })
            let expandIdSet = selData.map(item => item['id']);
            const params = {
                expandIdSet: expandIdSet,
                schedulingId: this.paikeId
            }
            page.aflpExpandCourseUpdata(params, res => {
                if(res.status === 1) {
                    this.selectDialogVisible = false;
                    Message.success('添加成功！');
                    this.aflpExpandCourseList();
                }else {
                    Message.error(res.message);
                }
            });
        },
        videoView(item){
            this.videoShow = true;
            let arr = item.appletExpandCourse.contentVideo.split('.')
            if(arr[arr.length - 1] === "ogg"){
                this.playerOptions['sources'][0]['src'] = item.appletExpandCourse.contentVideo; 
            }else if(arr[arr.length - 1] === "mp4"){
                this.playerOptions['sources'][1]['src'] = item.appletExpandCourse.contentVideo;
            }else if(arr[arr.length - 1] === "webm"){
                this.playerOptions['sources'][2]['src'] = item.appletExpandCourse.contentVideo;
            }else if(arr[arr.length - 1] === "3gp"){
                this.playerOptions['sources'][3]['src'] = item.appletExpandCourse.contentVideo;
            }
            this.videoDialogVisible = true;
        },
        videoClose(){
            this.videoDialogVisible = false;
            this.videoShow = false;
        },
  },
};
</script>

<style lang="less" scoped>
.checkWork {
    height: 100%;
    width: 100%;
    .checkWork-con{
        padding: 15px;
    }
    .vedio{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        .vedioImg{
            height: 220px;
            width: 80%;
            margin-left: 10%;
            background: #fff;
            border-radius: 25px;
            position: relative;
        }
        .vedioItem{
            flex: 0 0 15%;
            box-sizing: border-box;
            margin-right: 2%;
            margin-bottom: 40px; 
            .img{
                display: inline-block;
                border-top-left-radius:25px;
                border-top-right-radius:25px;
                cursor: pointer;
                width: 100%;
                height: 170px;
            }
            .delete{
                width: 100%;
                height: 50px;
                text-align: right;
            }
            .deleteIcon{
                cursor: pointer;
                display: inline-block;
                font-size: 24px;
                color: #FB0101;
                line-height: 50px;
                margin-right: 20px;
            }
            .vedioName{
                font-size: 16px;
                color: #969696;
                line-height: 50px;
                margin-left: 20px;
                height: 50px;
            }
            .vedioImg:hover{
                -webkit-box-shadow: #ccc 0px 10px 10px;
                -moz-box-shadow: #ccc 0px 10px 10px;
                box-shadow: #ccc 0px 10px 10px;
                transition: all  0.5s;
            }
        }
    }
    .addImg{
        height: 220px;
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        .add{
            width: 80%;
            height: 100%;
            background: #fff;
            border-radius: 25px;
            cursor: pointer;
            .icon{
                color: #BEBCBD;
                font-size: 40px;
                text-align: center;
                margin-top: 55px;
            }
            .words{
                color: #BEBCBD;
                font-size: 20px;
                text-align: center;
                margin-top: 30px;
            }
        }
    }
    .vedio>span{
        width: 100%;
    }
    /deep/ .el-loading-mask{
        z-index: 10;
    }
    /deep/ .el-loading-spinner{
        display: none;
    }
    /deep/ .el-loading-mask{
        border-radius: 25px;
    }
    .vedioItem:nth-child(6){
        margin-right: 0%;
    }
    .vedioItem:nth-child(12){
        margin-right: 0%;
    }
    .vedioItem:nth-child(18){
        margin-right: 0%;
    }
    .vedioItem:nth-child(24){
        margin-right: 0%;
    }
    .vedioItem:nth-child(30){
        margin-right: 0%;
    }
    .vedioItem:nth-child(36){
        margin-right: 0%;
    }
}
.sort-move {
    transition: transform 0.3s;
}
.transition-wrapper{
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
    .checkSucCard{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 760px;
        height: 475px;
        background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
        .checkSuc{
            position: absolute;
            left: 65px;
            top: 40px;
            width: 630px;
            height: 410px;
            background: #FFFCFD;
            border-radius: 135px;
            .tip{
                position: absolute;
                left: 50%;
                top: -16%;
                width: 400px;
                height: 110px;
                background: url("../../assets/image/tip.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .delete{
                position: absolute;
                top: 30%;
                width: 100%;
                .deleteImg{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .deleteImgTip{
                        letter-spacing:8px;
                    }
                }
            }
            .operation{
                position: absolute;
                left: 50%;
                top: 75%;
                width: 500px;
                height: 110px;
                transform: translateY(-50%) translateX(-50%);
                display: flex;
                flex-flow:  row nowrap;
                justify-content: space-between;
                align-items: center;
                letter-spacing:3px;
                .cancel{
                    width: 200px;
                    height: 60px;
                    border: 1px solid #2CBD74;
                    border-radius: 42px;               
                    color: #2CBD74;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
                .deleteImg{
                    width: 200px;
                    height: 60px;
                    background: #2CBD74;
                    border-radius: 42px;
                    color: #FFFCFD;;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
    .close{
                position: absolute;
                left: 110%;
                width: 50px;
                height: 50px;
                background: url("../../assets/image/close.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
                cursor: pointer;
            }
    .modal{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 1200px;
        height: 750px;
        background: #ECECEC;
        border-radius: 30px;
        transform: translateY(-50%) translateX(-50%);
        .header{
            height: 100px;
            width: 100%;
            border-bottom: 1px solid #ccc;
            .modalName{
                line-height: 100px;
                font-weight: bold;
                color: #333333;
                font-size: 30px;
                margin-left: 40px;
            }
            .btnCtrol{
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            .addBtn{
                margin-left: 30px;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .content{
            width: 100%;
            height: 600px;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            overflow-x: hidden; /*x轴禁止滚动*/
            overflow-y: scroll;/*y轴滚动*/
            .courseItem{
                flex: 0 0 25%;
                box-sizing: border-box;
                margin-bottom: 40px;
                .course{
                    height: 220px;
                    width: 80%;
                    margin-left: 10%;
                    background: #fff;
                    border-radius: 25px;
                    .img{
                        display: inline-block;
                        border-top-left-radius:25px;
                        border-top-right-radius:25px;
                        width: 100%;
                        height: 170px;
                    }
                    .check{
                        width: 100%;
                        height: 50px;
                        text-align: right;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .checkbox{
                        margin-right: 20px;
                    }
                    .vedioName{
                        font-size: 16px;
                        color: #969696;
                        line-height: 50px;
                        margin-left: 20px;
                        height: 50px;
                    }
                }
            }
        }
        .content::-webkit-scrollbar {
            display: none;/*隐藏滚动条*/
        }
    }
    /deep/ .el-input__inner{
        border-radius: 26px 0 0 26px;
        border: 1px solid #2CBD74;
    }
    /deep/ .el-button--success{
        background-color: #2CBD74;
        border-color: #2CBD74;
    }
    /deep/ .el-input-group__append, .el-input-group__prepend{
        border-radius: 0 26px 26px 0;
        padding: 0 25px;
        cursor: pointer;
        color: #fff;
        background-color: #2CBD74;
        border: 1px solid #2CBD74;
    }
    .mask {
        position: absolute;
        background: rgba(101, 101, 101, 0.8);
        color: #ffffff;
        opacity: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        cursor: pointer;
        .maskIconOne{
            font-size: 50px;
            margin-right: 30px;
        }
        .maskIconTwo{
            font-size: 50px;
        }
    }
    .mask h2 {
        text-align: center;
        margin-top: 25%;
    }
    .vedioImg:hover .mask {
        opacity: 1;
    }
    .opIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35%;
    }
</style>
<style lang="scss" scoped>
    .checkWork-con{
        height: calc(100vh - 110px);
        overflow-y: scroll;
    }
    .checkWork-con::-webkit-scrollbar {
        display: none;/*隐藏滚动条*/
    }
</style>